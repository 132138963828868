$php:           #8892BF !default;
$php-dark:      #4F5B93 !default;
$php-head:      #793862 !default;
$php-info:      #9c9 !default;
$php-info-dark: #696 !default;
$php-link:      #369 !default;
$php-back:      #F2F2F2 !default;
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// テーマカラーカスタム
$theme-colors: (
"php":              $php,
"php-dark":         $php-dark,
"php-head":         $php-head,
"php-info":         $php-info,
"php-info-dark":    $php-info-dark,
"php-link":         $php-link,
"php-back":         $php-back,
"blue":       $blue,
"indigo":     $indigo,
"purple":     $purple,
"pink":       $pink,
"red":        $red,
"orange":     $orange,
"yellow":     $yellow,
"green":      $green,
"teal":       $teal,
"cyan":       $cyan,
"white":      $white,
"gray":       $gray-400,
"gray-dark":  $gray-800
);

// グラデーションON
$enable-gradients: true;

// 背景に対する文字色の境界値(orange→白文字)
$yiq-contrasted-threshold: 160;

// 全体の文字サイズ(8割に変更)
$font-size-base: 0.8rem;

/* Bootstrap を読み込む */
@import "~bootstrap/scss/bootstrap.scss";
